import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import ContentText from '../Typography/ContentText'
const useStyles = makeStyles((theme) => ({
  mapTitle: {
    '& div': {
      textAlign: 'center',
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
  },
}))
const MapWrapperCard = ({ title, children }) => {
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Box className={classes.mapTitle}>
          <ContentText>{title}</ContentText>
        </Box>
        <Divider />
        <br />
        <Box>{children}</Box>
      </CardContent>
    </Card>
  )
}
export default MapWrapperCard
